<template>
  <div class="whitepapre template">
    <div :class="{top:true, layout:true,'start':start}">
      <div id="logo">
        <img @click="toPage" src="../assets/logo.png" /> WHITEPAPER
      </div>
      <div id="lang" @click="changeLang"><span>{{lang=='en'?'中':'En'}}</span></div>
    </div>
    <div v-show="start">
      <div class="banner layout"></div>
      <div :class="{'info':true,'active':infoActve}"  @click="start=false" @mouseenter="infoActve=true" @mouseleave="infoActve=false">
        <div class="mask"></div>
        <div class="img">
          <img src="https://cdn.blockwar.io/web_assets/img/whitepage_info_img.png" alt="">
        </div>
        <div class="txt">
          <h1>{{ lang=='en'?'Welcome to the world of Block War':'戰遊白皮書' }}</h1>
          <p>{{ lang=='en'?'Block War is the latest Game-Fi metaverse. All experiences in the world of Block War are built around the concept of Play-to-Earn, allowing players to not only get an unparalleled gaming experience, but also enjoy the unprecedented dividend brought by web 3.0 blockchain technology.':'戰遊世界是一個全新的Game-Fi元宇宙，戰遊世界的所有元素都緊緊圍繞Play-to-Earn（玩賺）的理念，讓玩家既獲得無與倫比的遊戲體驗，也享受到web3.0區塊鏈技術所帶來的時代紅利' }}</p>
          <div class="more mortina">Learn more<svg width="30" height="11" viewBox="0 0 30 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.5H28L16.2985 10.5" stroke="white" stroke-opacity="0.5"/>
            </svg>
            </div>
        </div>
      </div>
    </div>
    <div v-show="!start" class="layout main">
      <div class="layout_left" v-if="txt">
        <div class="back" @click="start=true"><icon_back />{{ lang=='en'?'Back':'返回' }}</div>
        <h1 class="h1">{{ lang=='en'?'Welcome':'戰遊白皮書' }}</h1>
        <h2 class="caption">{{lang=='en'?'OVERVIEW':'概覽'}}</h2>
        <ul :class="{'index':true,'hover':leftListHover}" @mouseenter="leftListHover=true" @mouseleave="leftListHover=false">
          <li v-for="(item,inx) in txt.content" :key="inx" :class="{'showchild':showH2Index==inx,'on':currentTitle==item.title}">
            <h2 @click="jumpTo(item.title,inx)" :class="{'on':currentTitle==item.title}">{{ item.title}}<icon_back v-if="item.children" /></h2>
            <ul v-if="item.children">
              <li v-for="(ii,i) in item.children" :key="i">
                <h3 @click="jumpTo(ii.title,inx)" :class="{'on':currentTitle==ii.title}">{{ ii.title }}</h3>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="layout_right" id="right" v-if="txt" @scroll="layoutRightScroll">
        <div class="content">
          <div class="caption" v-html="txt.caption"></div>
          <article  v-for="(item,inx) in txt.content" :key="inx">
            <h2 :name="item.title" :id="item.title">{{ item.title }}</h2>
            <div v-if="item.txt" class="txt" v-html="item.txt"></div>
            <dl v-if="item.children">
              <dd v-for="(ii,i) in item.children" :key="i">
                <h3 :name="ii.title" :id="ii.title">{{ii.title}}</h3>
                <div class="txt" v-if="ii.txt" v-html="ii.txt"></div>
              </dd>
            </dl>
          </article>
        </div>
      </div>
      <div class="side">
        <div class='at'>{{lang=='en'?'On current page':'在本頁'}}</div>
      <ul class="sidemenu">
        <li v-for="(item,inx) in txt.content" :key="inx" :class="{'showchild':showH2Index==inx,'on':currentTitle==item.title}">
          <h2 @click="jumpTo(item.title,inx)" :class="{'on':currentTitle==item.title}">{{ item.title}}<icon_back v-if="item.children" /></h2>
          <ul v-if="item.children">
            <li v-for="(ii,i) in item.children" :key="i">
              <h3 @click="jumpTo(ii.title,inx)" :class="{'on':currentTitle==ii.title}">{{ ii.title }}</h3>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    </div>
  </div>
</template>
<script setup>
  import {
    onMounted,computed,
    ref,nextTick
  } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
import common from "../utils/common";
import icon_back from '@/components/ui/icon/icon_back.vue'
import txt_cn from "./whitepaper_text_cn.js"
import txt_en from "./whitepaper_text_en.js"

  let router = useRouter();
  let store = useStore();
  let lang = computed(() => {
      return store.state.lang;
  })
  let txt = computed(()=>{
    if(lang.value=='en'){
      return txt_en;
    }
    else{
      return txt_cn;
    }
  });
  const toPage = ()=>{
    router.push("/")
  }
  const changeLang = ()=>{
    if (lang.value == 'en') {
        store.commit('set_lang', 'zh_cn');
        localStorage.setItem('lang', 'zh_cn');
    }
    else {
        store.commit('set_lang', 'en');
        localStorage.setItem('lang', 'en');
    }
  }

  // 是否處於初始界面
  let start = ref(true);

  // 左列表是否hover
  let leftListHover = ref(false);

  // 控制概覽的懸停樣式
  let infoActve = ref(false);

  // 當前的article
  let CurrentArticle = ref();
  // 當前標題
  let currentTitle = ref();
  // 展開左側第幾個大項菜單
  let showH2Index = ref(0);
  //跳轉到指定的位置
  const jumpTo = (t,inx)=>{
    document.getElementById(t).scrollIntoView(true);
    currentTitle.value = t;
    showH2Index.value = inx;
    // 賦值當前的article
    CurrentArticle.value = document.querySelectorAll("#right article")[inx];
  }

  // 滾動觸發事件
  const layoutRightScroll = (e)=>{
    window.requestAnimationFrame(()=>{
      // 找到最後一個小於等於當前滾動高度+可視半屏的article
      const h2List = document.querySelectorAll('#right article h2');
      for(let i=0;i<h2List.length;i++){
        if((h2List[i].offsetTop<=e.target.scrollTop+150) ){
          showH2Index.value = i;
          CurrentArticle.value = document.querySelectorAll("#right article")[i];
        }
      }
      // article如果沒有h3，h2為當前項
      currentTitle.value = CurrentArticle.value.querySelector('h2').getAttribute('name');
      // 如果有h3，取h3為當前項
      if(CurrentArticle.value.querySelectorAll('h3')){
        const h3List = CurrentArticle.value.querySelectorAll('h3');
        for(let i=0;i<h3List.length;i++){
          if((h3List[i].offsetTop<=e.target.scrollTop+150)){
            currentTitle.value = h3List[i].getAttribute('name');
          }
        }
      }
    });
  }

  onMounted(() => {
    nextTick(() => {
        // 默認第一個article的h2為當前項
        CurrentArticle.value = document.querySelector('#right article');
        currentTitle.value = CurrentArticle.value.querySelector('h2').getAttribute('name');
        showH2Index.value = 0;
      });
  });
</script>
<style lang="scss" src="./whitepaper.scss"></style>